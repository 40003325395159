<template>
  <div id="returnOrderList" style="background: white;">
    <div class="row">
      <div class="col-md-12 return-order-list-top">
        <div class="row" style="margin-left: 0px; margin-right: 0px;">
          <div
            class="col-lg-12 col-md-12 col-sm-12"
            style="padding-top: 15px;padding-right: 0px; padding-left: 0px;margin-bottom:15px"
          >
            <div class="row">
              <div 
                class="col-lg-6 col-md-12 col-sm-12 col-xs-12"
                style="padding-bottom: 15px"  
              
              >
                <input
                  style=""
                  type="text"
                  v-model="searchKey"
                  class="form-control"
                  id="input-store"
                  placeholder="Search Order by id"
                  value
                  name="voucher"
                />
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                      style="padding-bottom: 15px"
                  >
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">From</div>
                      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 from-to-date">
                        <datepicker v-model="filterDatefrom" placeholder="Select Date"></datepicker>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">To</div>
                      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 from-to-date">
                        <datepicker
                          calendar-class="my-datepicker_calendar"
                          v-model="filterDateto"
                          :disabledDates="disabledDates"
                          placeholder="Select Date"
                        ></datepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h2 class="title">Dispute & Return</h2>
          </div>
        </div>
      </div>
      <div id="table-content" class="col-md-12 col-sm-12">
        <div
          id="table-content"
          class="col-sm-12"
          style="padding-right: 0px; padding-left: 0px;overflow: auto;"
        >
          <table class="table table table-striped" style="border: 1px solid rgb(221, 221, 221)">
            <thead>
              <tr>
                <th scope="col" class="head-table">Order ID</th>
                <th scope="col" class="head-table text-left">Dispute Opened</th>
                <th scope="col" class="head-table text-left">Amount</th>
                <th scope="col" class="head-table text-left">Payment Type</th>
                <th scope="col" class="head-table text-left">Dispute Closed</th>
                <th scope="col" class="head-table">Dispute Status</th>
                <th scope="col" class="head-table">View more</th>
                <!-- <th scope="col" class="head-table">Dispute Reason</th>
                <th scope="col" class="head-table">Comment</th>-->
              </tr>
            </thead>
            <tbody>
              <div class="col-md-12" id="preview-area" v-if="blockLoading">
                <div class="row text-center">
                  <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                  </div>
                </div>
              </div>
              <tr>
                <td
                  colspan="7"
                  class="text-center col-md-12 norec"
                  v-if="blockLoading == false && cart_products.length === 0"
                >No Records Found!</td>
              </tr>

              <tr v-for="(itemp, index) in tableItems" :key="index">
                <td
                  class="text-left"
                  v-if="itemp.order_id !== null"
                ><span style="vertical-align: middle; font-weight: 700;">{{ itemp.order_id.order_env_number }}</span>
                <br />
                  {{
                  itemp.order_id.user_id != undefined
                  ? itemp.order_id.user_id.first_name != undefined
                  ? itemp.order_id.user_id.first_name
                  : " "
                  : " "
                  }}
                  {{
                  itemp.order_id.user_id != undefined
                  ? itemp.order_id.user_id.last_name != undefined
                  ? itemp.order_id.user_id.last_name
                  : " "
                  : " "
                  }}
                  </td>
                <td class="text-left"
                  v-if="itemp.order_id !== null"
                ><span>Date : {{ itemp.created_date.split("T")[0] }}</span> 
                <br/> 
                <span>Time: {{ itemp.created_time}}</span>
                </td>
                <td
                  class="text-left"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="itemp.order_line_id !== null"
                >Rs. {{ itemp.order_line_id.total_charge }}</td>
                <td
                  class="text-left"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="
                    itemp.order_id !== null && itemp.order_id.payment_type === 1
                  "
                >Supiri Pola Credit</td>
                <td
                  class="text-left"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="
                    itemp.order_id !== null && itemp.order_id.payment_type === 2
                  "
                >Cash On Delivery</td>
                <td
                  class="text-left"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="
                    itemp.order_id !== null && itemp.order_id.payment_type === 3
                  "
                >Bank Transfer</td>
                <td
                  class="text-left"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="
                    itemp.order_id !== null && itemp.order_id.payment_type === 4
                  "
                >Card Payment</td>
               <td
                  class="text-left"
                ><span>Date : {{ itemp.date.split("T")[0] }}</span> 
                <br/>
                <span>Time : {{ itemp.updated_time}}</span>
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; font-weight: 700;"
                >
                
                  <span class="badge" style="background-color: rgb(241, 202, 22); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="itemp.dispute_status === 1">
                    <B>Dispute Opened</B>
                  </span>

                  <span class="badge" style="background-color: rgb(255, 163, 0); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="itemp.dispute_status === 2">
                    <B>Dispute in Progress</B>
                  </span>

                   <span class="badge" style="background-color: green;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="itemp.dispute_status === 10">
                    <B>Merchanet Approved</B>
                  </span>

                  <span class="badge" style="background-color: red;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="itemp.dispute_status === 20">
                    <B>Merchant Rejected</B>
                  </span>

                  <span class="badge" style="background-color: rgb(163, 43, 185); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="itemp.dispute_status === 30">
                    <B>Escalated to Cochchi Admin</B>
                  </span>

                  <span class="badge" style="background-color: rgb(163, 43, 185); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="itemp.dispute_status === 31">
                    <B>Admin in Progress</B>
                  </span>

                  <span class="badge" style="background-color: green;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="itemp.dispute_status === 40">
                    <B>Admin Approved</B>
                  </span>

                </td>
                <td class="text-left" style="vertical-align: middle; font-weight: 700;">
                   <button
                    class="btn btn-primary"
                    style="background: #5779ae; color: #ffffff; border: none;"
                    @click="handdleChange(itemp)"
                  >View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row text-right">
        <div class="col-sm-12">
          <div class="mt-3">
            <b-pagination
              v-model="currentPage"
              :per-page="pageSize"
              :total-rows="rowcount"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import mixinDispute from "@/mixins/merchant/APIDispute";
export default {
  mixins: [mixinDispute],
  components: {
    Datepicker
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "Dispute & Return",
          active: true
        }
      ],
      subTotal: 0,
      total: 0,
      inputNumValue: 1,
      no_of_cart_items: 0,
      conditionCp: 1,
      cart_products: [],
      cart_products1: [],
      tableItems: [],
      cartSavedProductIds: [],
      idObj: {
        ids: []
      },
      selectedArray: [],
      pageSize: 5,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      filterDateto: new Date(),
      filterDatefrom: new Date(),
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 14) - 8640000)
      },
      blockLoading: true,
      selectedFilterStatus: "",
      singalDispute: {},
      modalDisputedata: {}
    };
  },
  watch: {
    "$store.state.merchant_selectedstore": function(val) {
      if (val !== "-1") {
        this.setReturnOrderByStoreId(val);
      } else {
        this.setReturnOrder();
      }
    },
    searchKey: function(val) {
      this.searchOrders(val);
    },
    filterDatefrom(val) {
      this.setReturnOrder();
      this.disabledDatesSet(val);
    },
    filterDateto(val) {
      this.setReturnOrder();
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchOrders(this.searchKey);
    }
  },
  created() {
    this.handleBreadcrumb();
    this.filterDatefrom.setDate(this.filterDatefrom.getDate() - 14);
    this.setReturnOrder();
  },
  mounted() {},
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    disabledDatesSet(val) {
      let toDate = val;
      this.filterDateto = null;
      this.disabledDates = {
          to: new Date(new Date(toDate).setFullYear(new Date(toDate).getFullYear(),new Date(toDate).getMonth(),new Date(toDate).getDate()) - 8640000)
      };
    },
    getDispatchCount: async function() {
      try {
        let params = ""
        if (this.searchKey == "") {
        params +="&from_date=" + moment(this.filterDatefrom).format("YYYY-MM-DD");
        params += "&to_date=" + moment(this.filterDateto).format("YYYY-MM-DD");
        }
        if (this.searchKey != "") {
          params += "order_no=" + this.searchKey;
        }
        let responce = await this.getDispatchListCount(params);
        this.rowcount = responce.result;
      } catch (error) {
        throw error;
      }
    },
    setReturnOrder: async function() {
      try {
        this.blockLoading = true;
        this.cart_products = [];
        this.tableItems = [];
        this.pagesize = 5;
        let params = "?page=" + this.currentPage;
        params += "&perpage=" + 5;
        if (this.searchKey != "") {
          params += "&order_no=" + this.searchKey;
        }
        if (this.searchKey == "") {
          params +="&from_date=" + moment(this.filterDatefrom).format("YYYY-MM-DD");
          params += "&to_date=" + moment(this.filterDateto).format("YYYY-MM-DD");
        }
        this.cart_products = await this.getDispatchList(params);

        // obj.created_time = this.cart_products.split("T")[1].split(".0")[0];

         this.cart_products = this.cart_products.map(obj => {
          // obj.created_time = obj.created_date.split("T")[1].split(".0")[0].split(".")[0];
          console.log("obj.");
          console.log(obj);
          console.log("obj.created_date");
          console.log(obj.created_date);
          obj.created_time = moment(obj.created_date).format("hh:mm A");
          if (obj.updated_date) {
            obj.updated_time = moment(obj.updated_date).format("hh:mm A");
            // obj.updated_time = obj.updated_date.split("T")[1].split(".0")[0].split(".")[0];
          }
          return obj;
         });

        this.tableItems = this.cart_products;

        this.getDispatchCount();
        this.blockLoading = false;
      } catch (error) {
        throw error;
      }
    },
    setReturnOrderByStoreId: async function(storeId) {
      try {
        this.blockLoading = true;
        this.cart_products = [];
        this.tableItems = [];
        this.currentPage = 1;
        this.cart_products = await this.getDispatchListById(storeId);
        this.tableItems = this.cart_products.slice(
          this.currentPage * this.pagesize - this.pagesize,
          this.currentPage * this.pagesize
        );
        this.rowcount = this.cart_products.length;
        this.blockLoading = false;
      } catch (error) {
        throw error;
      }
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      this.currentPage = currentPage;
      this.setReturnOrder();
    },
    handdleChange: function(item) {
      this.$router.push({
        name: "Buyer Return Order Configure View",
        params: { id: item.order_env_number }
      });
    },
    searchOrders: function(keyword) {
      this.blockLoading = true;
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.setReturnOrder();
        // this.tableItems = this.cart_products.slice(0, pagesize);
        // this.rowcount = this.cart_products.length;
      } else {
        this.setReturnOrder();
      //   this.tableItems = this.cart_products
      //     .filter(obj => {
      //       {
      //         return (
      //           obj.order_id.order_env_number &&
      //           obj.order_id.order_env_number.includes(keyword)
      //         );
      //       }
      //     })
      //     .slice(0, pagesize);
      //   this.rowcount = this.cart_products.length;
      }
      this.blockLoading = false;
    },
    handdleChange: function(item) {
      this.$router.push({ name: "Merchant Dispute Lines", params: { id: item._id, mode: "true" } });
    }
  }
};
</script>
<style>
.pagination li button {
  padding: 1px 12px;
  min-height: 1.42857143;
}
.pagination li.active > button {
  border: 2px solid rgb(0, 168, 255);
  background: rgb(0, 168, 255) none repeat scroll 0% 0%;
  color: white !important;
}
.pagination > li > span {
  line-height: auto;
}
.pagination > li > span {
  float: none;
}
#returnOrderList .vdp-datepicker input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
}
#returnOrderList .sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}
#returnOrderList .sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
#returnOrderList .sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
#returnOrderList .sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
#returnOrderList .sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
#returnOrderList .sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
#returnOrderList .sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
#returnOrderList .sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
#returnOrderList .sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
#returnOrderList .sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
#returnOrderList .sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
#returnOrderList .sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
#returnOrderList .sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
#returnOrderList .sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
#returnOrderList .sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
#returnOrderList .return-order-list-top {
  padding-top: 10px;
}
#returnOrderList .search-prd-block {
  text-align: right;
}
#returnOrderList .search-prd-block input[type="text"] {
  display: inline-block;
  width: 50%;
}
#returnOrderList .filter-block select {
  display: inline-block;
  width: 75%;
  height: 35px !important;
}
#returnOrderList .filter-block button {
  float: right;
}
#returnOrderList .from-to-block {
  padding-top: 15px;
}
#returnOrderList .pagination {
  margin-right: 20px;
}
.buyerprof-sbtn {
  background: #ffd338;
  color: #000000;
}

.head-table {
  min-width: 125px;
}

.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (max-width: 1348px) {
  #table-content {
    overflow-x: scroll;
  }
  table {
    width: 960px !important;
  }
  .img-thumbnail {
    max-width: 80px;
  }
}
@media screen and (max-width: 1200px) {
  #returnOrderList .search-prd-block input[type="text"] {
    width: 100%;
  }
  #returnOrderList .filter-block select {
    width: 90%;
  }
}
@media screen and (max-width: 725px) {
  #returnOrderList .filter-block select {
    width: 80%;
  }
}
@media screen and (max-width: 625px) {
  #returnOrderList {
    border: none !important;
  }
}
@media screen and (max-width: 560px) {
  #returnOrderList .from-block,
  .to-block {
    width: 100%;
  }
  #returnOrderList .from-to-block .dp-adjust {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  #returnOrderList .filter-block select {
    width: 70%;
  }
}
@media (max-width: 425px) {
  .nav-tabs > li > a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    margin-bottom: 6px;
    border: 1px solid #555;
    border-radius: 2px;
  }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    margin-right: 4px;
  }
  .norec {
    text-align: left;
  }
}
</style>

