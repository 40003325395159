import Domain from "../domain";
export default {
  methods: {
    getDispatchList: async function (params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/dispute/details" + params,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDispatchListCount: async function (params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/count/dispute/details?" + params,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDispatchListById: async function (sId) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/dispute/details?store=" + sId,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDisputeOrderLinesByOrderId: async function (id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/dispute/details/" + id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDisputeOrderLinesByOrderIdParam: async function (id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/dispute/details/?order_id=" + id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putDisputeOderSendToAdmin: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/order/status/dispute/send/admin",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putDisputeBankImage: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/order/bank/upload/image", obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putDisputeOderMerchantAccept: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/order/status/dispute/approve",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    haddleGetMerchatDisputeCancelReasonList: async function () {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/dispute/cancel/reasons",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putDisputeOderMerchantReject: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/order/status/dispute/reject",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getSettings: async function () {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/setting",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};